import { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { useLocation, Navigate, useNavigate } from "react-router-dom";

// pages
import Login from "src/pages/auth/Login";
import LoadingScreen from "src/components/LoadingScreen";
import { AuthLayoutComp } from "src/layouts/auth/index";
import SubscriptionKYC from "src/pages/dashboard/SubscriptionKYC";
import EmailOTP from "src/pages/auth/EmailOTP";

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [paymentPopupCanceled, setPaymentPopupCanceled] = useState(false);
  const [skipKyc, setSkipKyc] = useState(false);
  const { isAuthenticated, isInitialized, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      user &&
      user.subscription &&
      user.subscription.setup_status.payment === false
    ) {
      // Check if the flag is already set in local storage
      const flagSet = localStorage.getItem("paymentPopupFlag");

      if (!flagSet) {
        // Set the flag in local storage
        localStorage.setItem("paymentPopupFlag", "true");

        // Set the state to true to trigger the redirect
        setPaymentPopupCanceled(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (paymentPopupCanceled) {
      setPaymentPopupCanceled(false);
      navigate("/make-payment");
    }
  }, [paymentPopupCanceled, navigate]);

  // useEffect for checking KYC skip status
  useEffect(() => {
      const skippedAt = localStorage.getItem("kycSkippedAt");
      if (skippedAt) {
        const now = new Date().getTime();
        const skippedTime = parseInt(skippedAt, 10);
        const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day

        if (now - skippedTime < oneDay) {
          setSkipKyc(true);
        } else {
          // Skip has expired
          localStorage.removeItem("kycSkippedAt");
          setSkipKyc(false);
        }
      } else {
        setSkipKyc(false);
      }
  }, []);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return (
      <AuthLayoutComp>
        <Login />
      </AuthLayoutComp>
    );
  }

  if (
    user.subscription?.setup_status.payment &&
    !user.subscription?.setup_status?.otp
  ) {
    return <EmailOTP />;
  }

  if (
    user.subscription?.setup_status.payment &&
    !user.subscription?.setup_status?.kyc &&
    !skipKyc
  ) {
    return <SubscriptionKYC />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return <>{children}</>;
}
